<template>
  <span
    :class="['nuxt-svg-icon', descriptorClass]"
    :style="[styleVars]"
    v-html="icon"
  />
</template>

<script setup lang="ts">

	const props = withDefaults(
		defineProps<{
			name: string
			fontSize?: string | number
			size?: string | number
			fill?: string
			color?: string
			stroke?: string
			fillOpacity?: string
			strokeOpacity?: string
			strokeWidth?: string
			useOriginSize?: boolean,
			width?: string | number,
			height?: string | number
		}>(),
		{
			fontSize: undefined,
			size: undefined,
			fill: undefined,
			color: undefined,
			stroke: undefined,
			fillOpacity: undefined,
			strokeOpacity: undefined,
			strokeWidth: undefined,
			useOriginSize: false,
			width: undefined,
			height: undefined
		}
	);

	const descriptorClass = computed(() => {
		return {
			'use-origin-width': props.useOriginSize,
			'use-origin-height': props.useOriginSize
		};
	});

	const finalFontSize = computed(() => props.fontSize || props.size);
	const finalFill = computed(() => props.fill || props.color);

	const styleVars = computed(() => {
		const fontSizeCssVar =
			typeof finalFontSize.value === 'number'
				? `${finalFontSize.value}px`
				: props.fontSize;

		return {
			color: props.color,
			'--svg-icon-font-size': fontSizeCssVar,
			'--svg-icon-fill': finalFill.value,
			'--svg-icon-stroke': props.stroke,
			'--svg-icon-fill-opacity': props.fillOpacity,
			'--svg-icon-stroke-opacity': props.strokeOpacity,
			'--svg-icon-stroke-width': props.strokeWidth,
			'--svg-icon-width': props.width ? `${props.width}px` : props.size ? `${props.size}px` : 'auto',
			'--svg-icon-height': props.height ? `${props.height}px` : props.size ? `${props.size}px` : 'auto'
		};
	});

	const icon = ref('');

	watchEffect(async () => {
		try {
			const iconsImport = import.meta.glob('assets/icons/**/**.svg', {
				import: 'default',
				eager: false,
				query: {
					raw: ''
				}
			});

			const rawIcon = await iconsImport[`/assets/icons/${props.name}.svg`]() as string;
			const idMap: {
				[key: string]: string
			} = {};
			const idPattern = /id="([^"]*)"/g;
			let match;

			while ((match = idPattern.exec(rawIcon)) !== null) {
				idMap[match[1]] = `${match[1]}-${randomUUID(8)}`;
			}
			const referencePattern = new RegExp(Object.keys(idMap).map(id => `url\\(#${id}\\)`).join('|'), 'g');

			let processedIcon = rawIcon
				.replace(/id="([^"]*)"/g, (match, p1) => `id="${idMap[p1]}"`)
				.replace(referencePattern, match => match.replace(/url\(#([^)]*)\)/g, (refMatch, p1) => `url(#${idMap[p1]})`));

			icon.value = processedIcon;
		} catch {
			console.error(
				`[nuxt-svg-icons] Icon '${props.name}' doesn't exist in 'assets/icons'`
			);
		}
	});
</script>

<style>
.nuxt-svg-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.nuxt-svg-icon svg {
  font-size: var(--svg-icon-font-size) !important;
  fill: var(--svg-icon-fill) !important;
  stroke: var(--svg-icon-stroke) !important;
  fill-opacity: var(--svg-icon-fill-opacity) !important;
  stroke-opacity: var(--svg-icon-stroke-opacity) !important;
  stroke-width: var(--svg-icon-stroke-width) !important;
	width: var(--svg-icon-width) !important;
	height: var(--svg-icon-height) !important;
}

.use-origin-width * {
  width: var(--svg-origin-width--with-unit) !important;
}

.use-origin-height * {
  height: var(--svg-origin-height--with-unit) !important;
}
</style>
